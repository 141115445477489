<template>
  <v-card>
    <v-toolbar
      dark
      color="primary"
      dense
      class="mb-5"
    >
      <v-btn
        icon
        dark
        @click="close"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>Galeria</v-toolbar-title>
      <v-spacer />
    </v-toolbar>

    <v-form ref="form">
      <v-row
        justify="center"
        no-gutters
      >
        <v-col cols="10">
          <v-text-field
            v-model="gallery.name"
            label="Nome da Galeria *"
            :rules="$rules.required"
            required
            outlined
          />
        </v-col>
        <v-col cols="10">
          <v-file-input
            v-model="imagesUpload"
            outlined
            label="Clique para adicionar imagens do tamanho mínimo de 753x451 *"
            prepend-icon="mdi-camera"
            multiple
            required
            :rules="imageAlreadyExists ? [] : $rules.required"
            accept="image/jpeg, image/png"
            @change="onFilesSelected"
          />
        </v-col>
      </v-row>
    </v-form>

    <gallery
      :images="imagesPreview"
      :rules="$rules.required"
      @image-deleted="onDeleteImage"
    />

    <div class="d-flex justify-center my-5">
      <v-btn
        color="secondary"
        class="mr-3"
        @click="close"
      >
        Cancelar
      </v-btn>
      <v-btn
        v-if="loading === true"
        color="primary"
        loading
        @click="submit"
      />
      <v-btn
        v-else
        color="primary"
        @click="submit"
      >
        Salvar
      </v-btn>
    </div>
  </v-card>
</template>

<script>
import { createPhoto } from '@/services/photos-service';
import GalleryService from '../../../services/gallery.service';

export default {
  name: 'GalleryForm',
  props: {
    populateWith: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      imagesPreview: [],
      imagesUpload: null,
      imagesDeleted: [],
      images: [],
      gallery: {},
      loading: false,
    };
  },
  computed: {
    imageAlreadyExists() {
      return Boolean(this.gallery.galleryPhoto) || Boolean(this.imagesUpload);
    },
  },
  created() {
    if (this.populateWith.id) {
      this.gallery = this.populateWith;
      this.imagesPreview = [
        ...this.gallery?.galleryPhoto?.map((file) => file?.photo?.link),
      ];
      this.images = [...this.gallery?.galleryPhoto?.filter((file) => !file.id)];
    }
  },
  methods: {
    close() {
      this.$emit('close-dialog');
    },
    onFilesSelected(files) {
      if (files) {
        this.imagesPreview = [
          ...files.map((file) => URL.createObjectURL(file)),
          ...this.imagesPreview,
        ];
        this.images = [...files, ...this.images];
      }
    },
    onDeleteImage(index) {
      const image = this.imagesPreview?.splice(index, 1);
      this.imagesUpload?.splice(index, 1);
      this.images?.splice(index, 1);

      if (this.populateWith.id && image) {
        const galleryFind = this.gallery?.galleryPhoto?.find(
          (galleryPhotoFilter) => galleryPhotoFilter?.photo?.link === image[0],
        );
        this.imagesDeleted = [...this.imagesDeleted, galleryFind?.id];

        this.gallery = {
          ...this.gallery,
          galleryPhotoDeleted: this.imagesDeleted,
        };
      }
    },
    async submit() {
      if (this.$refs.form.validate() && this.imagesPreview.length > 0) {
        try {
          this.loading = true;
          const photos = await createPhoto(this.images);
          this.gallery = {
            ...this.gallery,
            photos: photos?.map((photo) => photo.id),
          };

          if (this.populateWith.id) {
            // eslint-disable-next-line max-len
            await GalleryService.updateGallery(this.gallery);
            this.$emit('gallery-edited', {
              ...this.gallery,
              photos: this.images,
              createdAt: new Date(),
            });
          } else {
            await GalleryService.createGallery(this.gallery);
            this.$emit('gallery-added', {
              ...this.gallery,
              photos: this.images,
              createdAt: new Date(),
            });
          }
          this.$refs.form.reset();
          this.$refs.form.resetValidation();
          this.close();
          this.$toast.success('Galeria cadastrada com sucesso');
          this.loading = false;
        } catch (e) {
          this.$handleHttpError(e);
          this.loading = false;
        }
      } else {
        this.$toast.error('Verifique os campos e tente novamente');
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped></style>
